import styled from 'styled-components';
import config from '../config';
export const Component = styled.header`
  background-image: url('/assets/icons/background/hexagon-dark.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  padding-bottom: 20.6rem;
  margin: 0 calc((100% - 138rem) / 2);

  nav {
    padding-top: 3.2rem;
    padding-bottom: 6.4rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    background-image: none;
    padding-bottom: 6rem;
    nav {
      padding-top: 1.6rem;
      padding-bottom: 4.8rem;
    }
  }

  @media (max-width: ${config.resolutions.desktopS}) {
    margin: unset;
  }
`;
