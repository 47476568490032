import React from 'react';
import { Text3 } from '../atoms/body';
import { Jumbo1 } from '../atoms/headers';
import Layout from '../atoms/layout';
import PropsPageTitle from '../interfaces/PageTitle';
import { Section } from './Components';

function PageTitle(props: PropsPageTitle): JSX.Element {
  const { children, title, subtitle } = props;
  return (
    <Layout>
      <Section>
        <Jumbo1>{title}</Jumbo1>
        <Text3 as="p" className="subtitle">
          {subtitle}
        </Text3>
        {children}
      </Section>
    </Layout>
  );
}

export default PageTitle;
