import styled from 'styled-components';
import config from '../../components/config';
import { Text3 } from '../../components/atoms/body';

const Question = styled.article`
  margin-bottom: 4.8rem;

  p {
    color: ${config.palette.darkBlueTransparent('0.6')};
    margin-top: 1.6rem;
  }
`;

export { Question };
