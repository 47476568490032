import React from 'react';
import Props from '../interfaces/Header';
import NavBar from '../NavBar';
import { Component } from './Components';

function Header(props: Props): JSX.Element {
  const {
    children,
    pageName,
    showBackground = true,
    showLanguages,
    className = ''
  } = props;
  return (
    <Component className={className}>
      <NavBar
        isDarkBackground={showBackground}
        isDarkBackgroundMobile={!showBackground}
        showLanguages={showLanguages}
        pageName={pageName}
      />
      {children}
    </Component>
  );
}

export default Header;
