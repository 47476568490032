import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle subtitle="" title="Términos y condiciones" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Bienvenido al Sitio Web de Ironhack</Text3>
          <Text3 as="p">
            Bienvenido al sitio web de Ironhack, y gracias por visitarnos.
            Esperamos que disfrute de la experiencia.
          </Text3>
          <Text3 as="p">
            Ironhack, Inc. (“<strong>Ironhack</strong>”, “
            <strong>nosotros</strong>”, “<strong>nos</strong>” o “
            <strong>nuestro</strong>”) es un proveedor internacional de cursos
            de Desarrollo Web y Diseño UX/UI con campus en diferentes partes del
            mundo. Estas Condiciones de Uso (“<strong>Condiciones</strong>”) son
            de aplicación al sitio web de Ironhack ubicado en{' '}
            <a href="https://www.ironhack.com/es">https://www.ironhack.com</a>,
            sus centros en todo el mundo y sus filiales y afiliados. Estas
            condiciones suponen un contrato legal entre usted (“
            <strong>usted</strong>”, “<strong>su</strong>”) y nosotros, y
            regulan el uso de todos los textos, datos, información, software,
            gráficos, fotografías y otros elementos (a los que hacemos
            referencia como “<strong>Materiales</strong>”), que tanto nosotros
            como nuestras filiales y afiliados puedan poner a su disposición,
            así como cualquier servicio (“<strong>Servicios</strong>”) que
            podamos ofrecer en nuestro sitio web (a todos los anteriores se les
            denomina en estas condiciones el “<strong>Sitio Web</strong>”)
          </Text3>
          <Text3 as="p">
            LEA ESTAS CONDICIONES ATENTAMENTE ANTES DE NAVEGAR POR ESTE SITIO
            WEB. LA UTILIZACIÓN DE ESTE SITIO WEB INDICA QUE HA LEÍDO Y ACEPTA
            ESTAS CONDICIONES. NO PUEDE UTILIZAR ESTE SITIO WEB SI NO ACEPTA
            ESTAS CONDICIONES. SI NO ESTÁ DE ACUERDO, NO UTILICE ESTE SITIO WEB.
          </Text3>
          <Text3 as="p">
            NOTA: ESTAS CONDICIONES CONTIENEN UNA DISPOSICIÓN SOBRE RESOLUCIÓN
            DE DISPUTAS Y ARBITRAJE, INCLUYENDO LA RENUNCIA A UNA DEMANDA
            COLECTIVA QUE AFECTA A SUS DERECHOS EN VIRTUD DE ESTAS CONDICIONES Y
            RELACIONADOS CON CUALQUIER DISPUTA QUE PUEDA TENER CON LA EMPRESA.
            TAL Y COMO SE ESTABLECE MÁS ABAJO, PUEDE RENUNCIAR AL ARBITRAJE
            INDIVIDUAL VINCULANTE Y LA RENUNCIA A LA DEMANDA COLECTIVA.
          </Text3>
          <Text3 as="p">
            Ironhack se reserva el derecho a cambiar, modificar, añadir o
            eliminar partes de estas Condiciones, bajo su exclusiva discreción y
            en cualquier momento. Revise estas Condiciones con regularidad.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CAMBIOS</Text2>
          <Text3 as="p">
            Nosotros, a nuestra exclusiva discreción, podemos modificar los
            Materiales y Servicios que ofrecemos o decidir modificar, suspender
            o interrumpir este Sitio Web en cualquier momento y sin notificación
            previa. Ocasionalmente, también podemos cambiar, actualizar, añadir
            o eliminar disposiciones (de forma colectiva, “modificaciones”) de
            estas Condiciones. La claridad beneficia a todo el mundo, por lo que
            prometemos informarle de cualquier modificación que se realice en
            estas Condiciones publicándolo en este Sitio Web.
          </Text3>
          <Text3 as="p">
            Si no está de acuerdo con tales modificaciones, su único recurso
            será dejar de utilizar este Sitio Web. El uso continuado de este
            Sitio Web tras la notificación de cualquiera de dichas
            modificaciones indica que reconoce, acepta y está de acuerdo con su
            vinculación a través de las mismas. Tenga también presente que estas
            Condiciones pueden ser reemplazadas por notificaciones o condiciones
            legales expresamente designadas ubicadas en páginas concretas de
            este Sitio Web. Dichas notificaciones o condiciones expresamente
            designadas se incorporarán a estas Condiciones y reemplazarán a las
            disposiciones de estas Condiciones designadas para ello.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">USO GENERAL</Text2>
          <Text3 as="p">
            Al utilizar este Sitio Web, promete que tiene al menos 18 años de
            edad. Si aún no tiene 18 años, ha de obtener el permiso de un adulto
            para utilizar este Sitio Web y aceptar sus Condiciones, y dicho
            adulto ha de ser un padre o tutor legal que acepte la
            responsabilidad de la utilización de este Sitio Web por su parte.
          </Text3>
          <Text3 as="p">
            Le invitamos a que utilice este Sitio Web con fines personales de
            consumo (“<strong>Fines Permitidos</strong>”). ¡Que lo disfrute!
          </Text3>
          <Text3 as="p">
            A través de estas Condiciones le otorgamos una licencia limitada,
            personal, no exclusiva y no transferible para utilizar y visualizar
            los Materiales. Su derecho para la utilización de los Materiales
            está supeditado al cumplimiento por Su parte de estas Condiciones.
            No tiene otros derechos sobre este Sitio Web o sobre ninguno de los
            Materiales, y no puede modificar, editar, copiar, reproducir, crear
            trabajos derivados de, aplicar ingeniería inversa, alterar, mejorar
            o explotar de forma alguna nada de este Sitio Web o de los
            Materiales. Si realiza copias de alguna de las partes de este Sitio
            Web durante su participación en alguno de los Fines Permitidos, le
            recomendamos que guarde copias de todos nuestros derechos de autor y
            otros derechos de propiedad tal y como aparecen en este Sitio Web.
          </Text3>
          <Text3 as="p">
            No obstante, si incumple alguna de estas Condiciones, la licencia
            anterior vencerá automáticamente y deberá destruir inmediatamente
            todos los Materiales que haya descargado o impreso (así como todas
            las copias de los mismos).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            UTILIZACIÓN DE ESTE SITIO WEB Y DE NUESTROS SERVICIOS
          </Text2>
          <Text3 as="p">
            Le agradecemos su visita a nuestro Sitio Web y le abrimos las
            puertas para que eche un vistazo sin compromiso alguno y sin
            registrarse.
          </Text3>
          <Text3 as="p">
            Sin embargo, para poder acceder a ciertos Servicios, a nuestros
            instructores o a los materiales específicos de un curso, deberá
            inscribirse primero y después ser aceptado en uno de nuestros
            Bootcamps de Desarrollo Web o Diseño UX/UI o en nuestros programas a
            tiempo parcial “part time” (“<strong>Bootcamp Ironhack</strong>”).
          </Text3>
          <Text3 as="p">
            Es posible que existan términos y condiciones adicionales de
            aplicación a ciertos servicios o a partes y funciones específicas
            del Sitio Web. Al inscribirse para obtener nuestros servicios acepta
            tales términos y condiciones.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ALTA EN IRONHACK</Text2>
          <Text3 as="p">
            Si está interesado en darse de alta en Ironhack, deberá enviar la
            siguiente información después de hacer clic sobre el botón “
            <a href="https://www.ironhack.com/es/cursos/aplicar">
              Apúntate a Ironhack
            </a>
            ” de este Sitio Web:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Nombre y apellido;</li>
              <li>Número de teléfono;</li>
              <li>Una dirección de correo electrónico activa;</li>
              <li>Género;</li>
              <li>Referencia; y</li>
              <li>
                Una breve descripción de por qué está interesado en Ironhack.
              </li>
            </ul>
            Durante el proceso de alta en Ironhack puede también proporcionar
            información adicional optativa para que podamos ofrecerle una
            experiencia más personalizada cuando utilice este Sitio Web, pero
            eso lo dejamos a su elección. Una vez que haya enviado la
            información solicitada, iniciaremos el proceso de asesoramiento
            sobre cuál es el curso que más que más se adapta a sus preferencias,
            y evaluaremos si usted cumple las condiciones para ser alumno de
            alguno de los cursos ofertados por Ironhack. En caso de que no envíe
            la información de la lista anterior, no podremos tramitar su alta en
            Ironhack ni brindarle nuestros servicios de asesoramiento de la
            forma más adecuada.
          </Text3>
          <Text3 as="p">
            Usted es responsable de cumplir estas Condiciones cuando accede a
            este Sitio Web. Es responsabilidad suya obtener y mantener el equipo
            y los servicios técnicos necesarios para acceder a, y utilizar este
            Sitio Web así como el pago de los cargos asociados. También es su
            responsabilidad mantener la confidencialidad de la información que
            nos proporcione durante su inscripción al Bootcamp Ironhack. Si cree
            que su información personal o seguridad con respecto a este Sitio
            Web han sido violadas de forma alguna, ha de notificárnoslo
            inmediatamente. Si necesita más información, visite la página de{' '}
            <a href="https://www.ironhack.com/es/legal/aviso-legal">
              Política de privacidad
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMUNICACIONES ELECTRÓNICAS</Text2>
          <Text3 as="p">
            Al utilizar este Sitio Web, acepta recibir comunicaciones
            electrónicas nuestras, incluyendo notificaciones mediante correo
            electrónico. Estas comunicaciones electrónicas pueden incluir
            notificaciones sobre tasas y cargos aplicables, información sobre
            transacciones y otra información relacionada con el Sitio Web o
            nuestros cursos. Estas comunicaciones electrónicas son parte de su
            relación con nosotros. Acepta que cualquier notificación, acuerdo,
            divulgación u otras comunicaciones que le enviemos por vía
            electrónica satisfarán cualquier requisito legal sobre
            comunicaciones, incluyendo que tales comunicaciones sean por
            escrito.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLÍTICA DE PRIVACIDAD</Text2>
          <Text3 as="p">
            Respetamos la información que nos proporciona y queremos asegurarnos
            de que conoce exactamente cómo utilizamos esa información. Revise,
            por favor, nuestra “<strong>Política de Privacidad</strong>” en la
            que queda todo explicado. Al utilizar este Sitio Web, acepta y
            reconoce que las transmisiones a través de Internet nunca son
            absolutamente seguras o privadas. Tenga siempre presente que
            cualquier información que se envíe a través de, o al Sitio Web puede
            ser interceptada por terceras personas y leída, incluso cuando está
            cifrada.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ENLACES A SITIOS DE TERCERAS PARTES</Text2>
          <Text3 as="p">
            Puede que quiera seguirnos, estar conectado con nosotros a través de
            las redes sociales, o acceder a otras páginas web a las que se hace
            referencia en nuestra página de blog o Sitio Web. Creemos que los
            enlaces son de utilidad y a veces en este Sitio Web ofrecemos
            enlaces a sitios web de terceros. Si utiliza estos enlaces,
            abandonará este Sitio Web. No tenemos la obligación de revisar los
            sitios web de terceras partes a los que puede acceder desde este
            Sitio Web, no controlamos los sitios web de terceras partes y no
            somos responsables de ningún sitio web de terceras partes (ni de los
            productos, servicios o contenidos disponibles a través de ninguno de
            ellos). Por tanto, no avalamos o representamos de forma alguna tales
            sitios web de terceras partes, la información, contenido, software,
            productos, Servicios o Materiales que se encuentran en los mismos o
            los resultados que se puedan obtener de su utilización. Si decide
            acceder a cualquiera de los sitios web a los que se enlaza desde
            este Sitio Web, lo hace bajo su propio riesgo y deberá cumplir las
            políticas de privacidad y los términos y condiciones de tales sitios
            web de terceros.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ENVÍOS</Text2>
          <Text3 as="p">
            Ciertas áreas de este Sitio Web (por ejemplo la función de
            comentarios del blog), le pueden permitir enviar opiniones,
            información, datos, texto, mensajes u otros materiales (“
            <strong>Envíos de Usuario</strong>”). Usted acepta ser el único
            responsable de todos sus Envíos de Usuario y que tales Envíos de
            Usuario tienen el carácter de no confidenciales y no sujetos a
            derechos de propiedad. Es más, no garantizamos que pueda editar o
            eliminar alguno de los Envíos de Usuario que haya enviado.
          </Text3>
          <Text3 as="p">
            Al enviar cualquier Envío de Usuario, nos promete que:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Usted posee todos los derechos sobre sus Envíos de Usuario
                (incluyendo entre otros, todos los derechos de reproducción y
                publicación de sus Envíos de Usuario) o, de forma alternativa,
                ha adquirido todos los derechos necesarios sobre sus Envíos de
                Usuario que le permiten otorgarnos a nosotros los derechos sobre
                sus Envíos de Usuario tal y como se describen en estas
                Condiciones;
              </li>
              <li>
                Ha pagado y pagará en su totalidad, todas las tasas de
                licencias, tasas de aduanas, y otras obligaciones financieras,
                sean del tipo que sean, que surjan del uso y la explotación
                comercial de sus Envíos de Usuario;
              </li>
              <li>
                Sus Envíos de Usuario no infringen derechos de autor, marcas
                registradas, patentes, secretos comerciales u otros derechos de
                propiedad intelectual, derechos de privacidad o cualquier
                derecho legal o moral de terceras partes;
              </li>
              <li>
                Acepta por voluntad propia a renunciar a todos los “derechos
                morales” que pueda tener sobre sus Envíos de Usuario;
              </li>
              <li>
                No tiene constancia de que la información incluida en sus Envíos
                de Usuario sea falsa, incorrecta o engañosa;
              </li>
              <li>
                Sus Envíos de Usuario no infringen ninguna ley (incluyendo pero
                sin limitarse a, aquellas que rigen el control de las
                exportaciones, la protección de los consumidores, la competencia
                desleal, las políticas antidiscriminatorias, o la falsa
                propaganda);
              </li>
              <li>
                Sus Envíos de Usuario no son, y no pueden ser razonablemente
                considerados como difamatorios, injuriosos, amenazadores,
                tendenciosos u ofensivos desde un punto de vista racial, étnico
                o religioso, constitutivos de amenaza o coacción ilegal a ningún
                individuo, asociación o corporación, vulgares, pornográficos,
                obscenos o invasivos de la privacidad de otras personas;
              </li>
              <li>
                No recibirá en ningún momento compensación o consideración de
                terceras partes por el envío de sus Envíos de Usuario;
              </li>
              <li>
                Sus Envíos de Usuario no incluyen materiales de sitios web de
                terceras partes o direcciones postales, direcciones de correo
                electrónico, información de contacto o números de teléfono (que
                no sea el suyo propio);
              </li>
              <li>
                Sus Envíos de Usuario no contienen virus, gusanos, spyware,
                adware u otros programas o archivos potencialmente dañinos;
              </li>
              <li>
                Sus Envíos de Usuario no contienen información alguna de
                carácter confidencial, protegida con derechos de propiedad, o
                personal; y
              </li>
              <li>
                Sus Envíos de Usuario no contienen o constituyen anuncios
                publicitarios no solicitados o autorizados, material de
                promoción, correo basura, spam, cartas en cadena, sistemas
                piramidales o cualquier otra forma de convocatoria.
              </li>
            </ul>
            Al hacer un Envío de Usuario, nos otorga una licencia irrevocable,
            perpetua, transferible, no exclusiva, pagada en su totalidad,
            internacional y libre de cargos, (sublicenciable a través de
            múltiples niveles) para:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Utilizar, distribuir, reproducir, modificar, adaptar, publicar,
                traducir, ejecutar públicamente y mostrar públicamente sus
                Envíos de Usuario (o cualquier modificación de los mismos), en
                parte o en su totalidad, en cualquier formato o medio
                actualmente conocido o que se desarrolle en el futuro;
              </li>
              <li>
                Utilizar (y permitir que otros utilicen) sus Envíos de Usuario
                de cualquier forma y para cualquier fin (incluyendo pero sin
                limitarse a fines comerciales) que consideremos apropiados bajo
                nuestra exclusiva discreción (incluyendo entre otros, la
                incorporación de sus Envíos de Usuario o cualquier modificación
                de los mismos, en parte o en su totalidad, a cualquier
                tecnología, producto o servicio);
              </li>
              <li>
                Mostrar anuncios relacionados con sus Envíos de Usuario y
                utilizar sus Envíos de Usuario con fines propagandísticos y
                promocionales.
              </li>
            </ul>
            No toleramos Envíos de Usuario perjudiciales u ofensivos en nuestro
            Sitio Web. Podemos, aunque no tenemos la obligación de, hacer una
            selección previa de los Envíos de Usuario o controlar cualquier
            espacio de este Sitio Web a través del cual se pueden enviar Envíos
            de Usuario. No tenemos la obligación de alojar, publicar o
            distribuir ningún Envío de Usuario en o a través de este Sitio Web y
            podemos eliminar en cualquier momento, o rechazar cualquier Envío de
            Usuario por el motivo que sea. No somos responsables de las
            pérdidas, robos o daños, sean del tipo que sean, de los Envíos de
            Usuario. Además, acepta que podemos divulgar libremente sus Envíos
            de Usuario a terceras partes sin obligaciones de confidencialidad
            por parte del receptor.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ACTIVIDADES NO AUTORIZADAS</Text2>
          <Text3 as="p">
            Para que no haya duda alguna, le autorizamos a utilizar este Sitio
            Web únicamente para los Fines Permitidos. Queda prohibida cualquier
            otra utilización de este Sitio Web que sobrepase los Fines
            Permitidos, constituyendo un uso no autorizado de este Sitio Web.
            Esto es así porque en lo que a usted y nosotros respecta, todos los
            derechos de este Sitio Web son de nuestra propiedad.
          </Text3>
          <Text3 as="p">
            El uso no autorizado de este Sitio Web puede resultar en una
            violación de las leyes de propiedad intelectual internacionales y de
            varios estados de los Estados Unidos de América. Como queremos que
            esta relación sea lo más sana posible, le proporcionamos unos
            ejemplos de cosas que debe evitar. Por tanto, a menos que disponga
            de un permiso nuestro que establezca lo contrario, no tiene
            autorización para utilizar este Sitio Web de ninguna de las formas
            siguientes (lo que aquí se muestra son solo ejemplos, y la lista no
            es una lista completa de todo lo que no tiene permiso para hacer):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Para cualquier fin público o comercial que incluya la
                utilización de este Sitio Web en otro sitio o a través de un
                entorno informático en red;
              </li>
              <li>
                De una forma que modifique, muestre públicamente, ejecute
                públicamente, reproduzca o distribuya nada de este Sitio Web;
              </li>
              <li>
                De una forma que viole cualquier ley, normativa, regla, orden,
                tratado o legislación de otro tipo local, estatal, nacional,
                extranjera o internacional;
              </li>
              <li>Para acosar, hostigar o perjudicar a otro individuo;</li>
              <li>
                Para suplantar a cualquier persona o entidad o falsear de alguna
                otra forma su afiliación con una persona o entidad;
              </li>
              <li>
                Para interferir con, o alterar este Sitio Web o los servidores o
                redes conectados al mismo;
              </li>
              <li>
                Para utilizar cualquier minería de datos, robots o métodos
                similares de recopilación u obtención de datos relacionados con
                este Sitio Web; o
              </li>
              <li>
                Intentar obtener acceso no autorizado a cualquier sección de
                este Sitio Web o cualquier otra cuenta, sistema informático o
                redes conectadas a este Sitio Web, bien sea mediante piratería,
                extracción de contraseñas, o cualquier otro medio.
              </li>
            </ul>
            Acepta contratar abogados para nuestra defensa si infringe estas
            Condiciones y la infracción resulta en un problema para nosotros.
            También acepta asumir los costes de cualquier daño que tengamos que
            pagar como resultado de sus infracciones. Usted es el único
            responsable de las infracciones que cometa con respecto a estas
            Condiciones. Nos reservamos el derecho de asumir la defensa y el
            control exclusivos de todo asunto sujeto de cualquier otra forma a
            una indemnización por su parte, y en tal caso, acepta cooperar con
            nuestra defensa frente a tal demanda.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DERECHOS DE PROPIEDAD</Text2>
          <Text3 as="p">
            “Ironhack” es una marca registrada de nuestra propiedad. Otras
            marcas, nombres y logotipos en este Sitio Web son propiedad de sus
            respectivos dueños.
          </Text3>
          <Text3 as="p">
            A menos que se especifique lo contrario en estas Condiciones, todos
            los Materiales, incluyendo su disposición en este Sitio Web son de
            nuestra exclusiva propiedad, Copyright © 2018. Quedan reservados
            todos los derechos no garantizados explícitamente en este documento.
            A menos que la legislación aplicable así lo exija, queda prohibida
            toda reproducción, distribución, modificación, retransmisión o
            publicación de todo material con derechos de propiedad intelectual
            sin el consentimiento expreso por escrito del propietario de la
            propiedad intelectual o la licencia.
          </Text3>
          <Text3 as="p">
            Antes de utilizar o reproducir cualquier marca comercial, nombre,
            logotipo o contenido de la forma que sea, póngase en contacto con
            nosotros para solicitar el permiso. No obtener tal permiso supone
            una violación de la legislación internacional que protege al autor o
            propietario de la marca comercial, el nombre o el logotipo o
            cualquier otro contenido y Usted quedará expuesto a las sanciones
            económicas o penales específicas.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            VIOLACIÓN DE LOS DERECHOS DE PROPIEDAD INTELECTUAL
          </Text2>
          <Text3 as="p">
            Nosotros respetamos los derechos de propiedad intelectual de otros y
            esperamos que usted haga lo mismo. En consecuencia, tenemos la
            política de eliminar cualquier Envío de Usuario que viole los
            derechos de propiedad intelectual de otros, suspendiendo el acceso a
            este Sitio Web (o a cualquier parte del mismo) a todo aquel usuario
            que utilice este Sitio Web en violación de los derechos de propiedad
            intelectual de otros, o suspendiendo cuando las circunstancias lo
            requieran, el Bootcamp Ironhack de todo aquel usuario que utilice
            este Sitio Web en violación de los derechos de propiedad intelectual
            de otros. En virtud del Título 17 del Código de los Estados Unidos,
            hemos implantado procedimientos para recibir notificaciones por
            escrito de las demandas por infracción de derechos de propiedad
            intelectual y para procesar tales demandas de acuerdo con tal ley.
            Si cree que sus derechos de autor u otros derechos de propiedad
            intelectual han sido violados por un usuario de este Sitio Web,
            envíe una notificación por escrito a Ironhack:
          </Text3>
          <Text3 as="p">A/A: Agente de la DMCA de Ironhack</Text3>
          <Text3 as="p">
            Correo electrónico:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            Para asegurarse de que el tema se trata de forma inmediata, la
            notificación por escrito ha de:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Incluir Su firma física o electrónica;</li>
              <li>
                Identificar el trabajo protegido con derechos de autor o
                cualquier otra propiedad intelectual supuestamente violados;
              </li>
              <li>
                Identificar el material supuestamente violado de forma lo
                suficientemente detallada como para que nosotros podamos
                localizar dicho material;
              </li>
              <li>
                Incluir la información adecuada para que podamos ponernos en
                contacto con usted (incluyendo su dirección postal, número de
                teléfono y dirección de correo electrónico);
              </li>
              <li>
                Incluir una declaración indicando que usted, de buena fe,
                considera que el uso del material con derechos de autor u otros
                derechos de propiedad intelectual no está autorizado por el
                propietario, el agente del propietario o la ley;
              </li>
              <li>
                Incluir una declaración indicando que la información en la
                notificación por escrito es correcta; e
              </li>
              <li>
                Incluir una declaración, bajo pena de perjurio, indicando que
                tiene autorización para actuar en nombre del propietario de los
                derechos de autor o de cualquier otro derecho de propiedad
                intelectual.
              </li>
            </ul>
            A menos que la notificación haga referencia a la violación de los
            derechos de autor o de otros derechos de propiedad intelectual, el
            Agente de la DMCA de Ironhack no podrá encargarse del asunto citado.
          </Text3>
          <Text3 as="p">Envío de una contranotificación de la DMCA </Text3>
          <Text3 as="p">
            En caso de que eliminemos o limitemos el acceso al material
            protegido por derechos de autor que usted ha proporcionado, le
            enviaremos una notificación si tal eliminación está sujeta a la
            recepción válida de un aviso de eliminación de la Ley de Derechos de
            Autor de la Era Digital (Digital Millennium Copyright Act, DMCA). En
            respuesta, usted puede proporcionar al Agente de la DMCA de Ironhack
            una contranotificación por escrito que incluya la siguiente
            información:
          </Text3>
          <Text3 as="p">
            <ol>
              <li>Su firma física o electrónica;</li>
              <li>
                Identificación del material eliminado o al cual se ha limitado
                el acceso, y la localización en la que aparecía el material
                antes de su eliminación o de que se limitara su acceso;
              </li>
              <li>
                Una declaración bajo pena de perjurio, indicando que usted, de
                buena fe, considera que el material ha sido eliminado o su
                acceso limitado como resultado de un error o un fallo en la
                identificación del material a eliminar o a limitar su acceso;{' '}
              </li>
              <li>
                Su nombre, dirección postal y número de teléfono, y una
                declaración en la que indica que acepta la jurisdicción de un
                tribunal del distrito federal en el que se encuentre su
                dirección postal, o si su dirección postal está fuera de los
                Estados Unidos, de cualquier distrito judicial en el que podamos
                estar ubicados, y que aceptará la citación de la demanda de la
                persona que haya facilitado la notificación sobre el material
                supuestamente infractor o de su agente.{' '}
              </li>
            </ol>
            Suspensión de Infractores Reincidentes
          </Text3>
          <Text3 as="p">
            Nos reservamos el derecho, bajo nuestra exclusiva discreción, de
            suspender un Bootcamp Ironhack o el acceso a este Sitio Web o
            Servicio a cualquier usuario que sea objeto de notificaciones
            repetidas de la DMCA u otras infracciones. Las notificaciones y
            contranotificaciones deberán satisfacer los requisitos legales
            vigentes en ese momento impuestos por la DMCA; para más información,
            véase la página https://www.copyright.gov/. Antes de presentar una
            notificación o una contranotificación, consulte a su asesor legal
            así como la sección 512 del título 17 del Código de los Estados
            Unidos, dado que, en virtud de la DMCA existen sanciones por
            reclamaciones falsas.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">EXENCIÓN DE GARANTÍAS</Text2>
          <Text3 as="p">
            ESTE SITIO WEB SE PROPORCIONA "TAL CUAL" Y "CON TODOS LOS ERRORES"
            QUE PUEDA TENER Y USTED ASUME TODOS LOS RIESGOS RELATIVOS A SU
            CALIDAD Y RENDIMIENTO.
          </Text3>
          <Text3 as="p">
            NO PROMETEMOS QUE EL SITIO WEB O SU CONTENIDO, LOS SERVICIOS O
            FUNCIONES SE OFREZCAN DE MANERA ININTERRUMPIDA, OPORTUNA, SEGURA O
            LIBRE DE ERRORES O QUE LOS DEFECTOS SEAN CORREGIDOS.
          </Text3>
          <Text3 as="p">
            EL SITIO WEB Y SU CONTENIDO SE PROPORCIONA “TAL CUAL” Y “SEGÚN
            DISPONIBILIDAD”.
          </Text3>
          <Text3 as="p">
            NO PODEMOS GARANTIZAR QUE LOS ARCHIVOS O DATOS QUE DESCARGUE DE ESTE
            SITIO ESTÉN LIBRES DE VIRUS O CONTAMINACIÓN ALGUNA, O DE ELEMENTOS
            DESTRUCTIVOS.
          </Text3>
          <Text3 as="p">
            NO RECONOCEMOS GARANTÍA DE NINGÚN TIPO, EXPRESA, IMPLÍCITA O LEGAL,
            INCLUIDAS LAS GARANTÍAS DE PRECISIÓN CON RELACIÓN A ESTE SITIO WEB,
            INCLUYENDO SIN LIMITARSE A, LAS GARANTÍAS IMPLÍCITAS DE
            COMERCIABILIDAD, ADECUACIÓN A UN FIN O USO CONCRETOS, TITULARIDAD, Y
            NO CONTRAVENCIÓN DE LOS DERECHOS DE PROPIEDAD INTELECTUAL.
          </Text3>
          <Text3 as="p">
            TAMBIÉN DECLINAMOS TODA RESPONSABILIDAD POR LAS ACCIONES, OMISIONES
            Y CONDUCTA DE TERCERAS PARTES EN CONEXIÓN O RELACIONADOS CON SU USO
            DEL SITIO WEB O LOS SERVICIOS DE IRONHACK.
          </Text3>
          <Text3 as="p">
            ESTO QUIERE DECIR QUE NO PODEMOS PROMETER QUE EL SITIO WEB CAREZCA
            DE PROBLEMA ALGUNO.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESULTADOS</Text2>
          <Text3 as="p">
            No ofrecemos garantías con respecto a los resultados que se puedan
            obtener con el uso de este Sitio Web o con la exactitud o fiabilidad
            de la información obtenida a través del mismo. Ningún consejo ni
            información, ya sea oral o por escrito, que Usted haya obtenido de
            este Sitio Web o de nosotros, nuestras filiales u otras empresas
            afiliadas, otorga ninguna garantía. No asumimos compensación
            equitativa de ningún tipo.
          </Text3>
          <Text3 as="p">
            Hacemos todo lo posible por contratar a los mejores instructores y
            personal para ofrecerle la mejor experiencia posible en el Bootcamp
            Ironhack. Podemos presentar a un instructor específico del Bootcamp
            Ironjack con fines publicitarios, pero eso no supone una
            recomendación de tal instructor o una garantía de que el curso al
            que usted se inscriba esté dirigido por ese instructor. No hacemos
            declaraciones sobre la calidad o naturaleza de ningún instructor del
            Bootcamp Ironhack.
          </Text3>
          <Text3 as="p">
            Es posible que, de vez en cuando, hagamos publicidad o promoción de
            los resultados de ciertos alumnos del Bootcamp Ironhack en este
            Sitio Web, en las redes sociales o en centros de promoción. En
            ningún caso Ironhack garantiza ningún resultado, trabajo, sueldo o
            fin vocacional. El Bootcamp Ironhack no es un programa de inserción
            laboral y ciertos resultados no podrán ser replicados. Nos
            esforzamos por ser absolutamente transparentes con los testimonios
            que presentamos de los alumnos de Ironhack y de resultados previos,
            incluyendo las calificaciones medias y las excepciones. Nuestro
            objetivo es ofrecer a los participantes del Bootcamp Ironhack los
            datos necesarios para comprender que los resultados del pasado no
            son indicadores de los resultados del futuro.
          </Text3>
          <Text3 as="p">
            EL HECHO DE QUE UN ALUMNO DE IRONHACK HAYA CONSEGUIDO UN BUEN
            TRABAJO TRAS COMPLETAR EL BOOTCAMP IRONHACK NO GARANTIZA QUE USTED
            TENGA EL MISMO RESULTADO. LOS RESULTADOS SON ALTAMENTE VARIABLES EN
            FUNCIÓN DE LA UBICACIÓN Y DEPENDEN DE CONDICIONES DE MERCADO QUE SE
            ESCAPAN A NUESTRO CONTROL. NOSOTROS NO GARANTIZAMOS NINGÚN
            RESULTADO.
          </Text3>
          <Text3 as="p">
            Completar un Bootcamp Ironhack no garantiza que se vaya a convertir
            en un experto en la materia impartida. Nuestros instructores y
            personal trabajan para dirigir el aprendizaje a través del Bootcamp
            Ironhack, pero algunos estudiantes no finalizan satisfactoriamente.
            Algunos estudiantes que se gradúan en el Bootcamp Ironhack no
            consiguen un trabajo en su campo. Algunos estudiantes no se gradúan
            y algunos estudiantes no adquieren las habilidades y conocimientos
            de la materia impartida en el Bootcamp Ironhack. Antes de
            inscribirse en un Bootcamp Ironhack, es recomendable que hable con
            un Director de Programa de Ironhack sobre las diferentes
            localizaciones y plazos, requisitos de admisión, tasas y gastos
            asociados así como sobre su propia capacidad.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIMITACIÓN DE RESPONSABILIDAD</Text2>
          <Text3 as="p">
            NO NOS HACEMOS RESPONSABLE DE LOS DAÑOS RESULTANTES DE SU
            VISUALIZACIÓN, COPIA O DESCARGA DE LOS MATERIALES DE ESTE SITIO WEB
            HASTA DONDE LO PERMITA LA LEGISLACIÓN APLICABLE. EN NINGÚN CASO NOS
            HAREMOS RESPONSABLES DE LOS DAÑOS INDIRECTOS, EXTRAORDINARIOS,
            EJEMPLARES, PUNITIVOS, ESPECIALES, ACCIDENTALES O CONSECUENTES
            (INCLUYENDO LA PÉRDIDA DE DATOS, INGRESOS, BENEFICIOS, USOS U OTROS
            BENEFICIOS ECONÓMICOS), SIN IMPORTAR EL MODO EN QUE SE HAYAN
            PRODUCIDO, INCLUSO EN EL CASO DE QUE SEPAMOS DE LA POSIBILIDAD DE
            QUE ESTOS SE PRODUZCAN.
          </Text3>
          <Text3 as="p">
            Algunas jurisdicciones no permiten limitaciones de responsabilidad,
            en cuyo caso estas no serían aplicables.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INDEMNIZACIÓN</Text2>
          <Text3 as="p">
            Usted acepta indemnizar y eximir de culpa y cargo a Ironhack, sus
            directivos, administradores, accionistas, empleados, agentes,
            filiales o asociados, de todo tipo de reclamaciones, demandas,
            pérdidas, responsabilidad o gastos (incluyendo honorarios de
            abogados) impuestos por terceros como resultado o en relación con el
            uso de este Sitio Web.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LEGISLACIÓN LOCAL CONTROL DE EXPORTACIÓN</Text2>
          <Text3 as="p">
            Nosotros controlamos y dirigimos este Sitio Web desde nuestra sede
            en los Estados Unidos de América, y es posible que haya partes de
            este Sitio Web que no sean adecuadas o no estén disponibles en otros
            lugares. Si utiliza este Sitio Web fuera de los Estados Unidos de
            América, es responsabilidad suya cumplir con las leyes aplicables.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            SOLUCIÓN DE DISPUTAS Y ARBITRAJES RENUNCIA A UNA DEMANDA COLECTIVA
          </Text2>
          <Text3 as="p">
            Lea esta Disposición con atención. Afecta a sus derechos legales.
          </Text3>
          <Text3 as="p">
            Esta Disposición de Solución de Disputas y Arbitrajes; Renuncia a
            una Demanda Colectiva (esta “<strong>Disposición</strong>”) facilita
            la resolución rápida y eficaz de cualquier disputa que pueda surgir
            entre usted y nosotros, por ejemplo, demandas o controversias, ya
            estén basadas en contratos, leyes, normativas, ordenanzas, delitos
            (incluyendo sin limitarse a, fraude, falseamiento, beneficios
            fraudulentos o negligencia), o cualquier otra teoría conforme a
            derecho o equidad, incluyendo la validez, exigibilidad o alcance de
            esta Disposición (a excepción de la aplicabilidad de la cláusula de
            Renuncia a una Demanda Colectiva siguiente). Por tanto, “disputa”
            debe interpretarse en el sentido más amplio posible conforme a la
            ley, e incluye cualquier demanda contra otras partes en relación con
            los servicios o productos que se le ofrecen o facturan (como
            nuestros licenciatarios, proveedores, distribuidores o terceros
            vendedores), siempre que usted presente demandas contra nosotros en
            el mismo procedimiento.
          </Text3>
          <Text3 as="p">
            Esta Disposición establece que todas las disputas entre usted y
            nosotros deberán resolverse mediante un arbitraje vinculante, porque
            la aceptación de estas Condiciones constituye una renuncia a su
            derecho a una demanda y a la oportunidad de ser escuchado por un
            juez o jurado. Nosotros así lo preferimos porque creemos que el
            arbitraje es más eficaz y menos polémico que el litigio. Para que no
            quepa duda alguna, en el arbitraje no hay jueces o jurado, y la
            revisión por un tribunal está limitada. El árbitro ha de guiarse por
            este acuerdo y tiene potestad para determinar los mismos daños y
            soluciones que un tribunal (incluyendo los honorarios de los
            abogados). Sin embargo, usted puede renunciar a esta disposición, lo
            que supondría tener el derecho u oportunidad de presentar demandas
            en un tribunal, ante un juez o un jurado, o a participar o ser
            representado en un caso presentado en un tribunal por otros
            (incluyendo pero sin limitarse a las demandas colectivas). USTED Y
            NOSOTROS ACEPTAMOS QUE, SALVO LO DISPUESTO A CONTINUACIÓN, CUALQUIER
            DISPUTA, SEGÚN SE DEFINE ANTERIORMENTE, TANTO SI SON ACTUALES COMO
            SI ESTÁN BASADAS EN ACTOS U OMISIONES DEL PASADO O DEL FUTURO, SE
            RESOLVERÁN, EXCLUSIVA Y FINALMENTE, MEDIANTE ARBITRAJE VINCULANTE EN
            LUGAR DE EN LOS TRIBUNALES DE ACUERDO CON ESTA DISPOSICIÓN.
          </Text3>
          <Text3 as="p">
            Resolución de una Reclamación Antes del Arbitraje
          </Text3>
          <Text3 as="p">
            Para todas las disputas, tanto sin son cursadas en los tribunales o
            mediante arbitraje, ha de darnos primero la oportunidad de
            resolverlas. El primer paso es enviarnos un correo electrónico a la
            dirección <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>{' '}
            incluyendo la siguiente información: (1) su nombre, (2) su
            dirección, (3) una descripción por escrito de la reclamación y la
            base de la disputa, y (4) una descripción de la solución específica
            que espera. Si no resolvemos su disputa en un plazo de cuarenta y
            cinco (45) días tras la recepción de su notificación, puede entonces
            cursar la disputa para su resolución mediante arbitraje. Solo puede
            presentar la disputa en un tribunal bajo las circunstancias que se
            describen a continuación.
          </Text3>
          <Text3 as="p">
            Exclusiones del Arbitraje / Derecho a la Autoexclusión
          </Text3>
          <Text3 as="p">
            Sin perjuicio de lo anterior, usted o nosotros podemos decidir
            cursar una disputa en los tribunales y no mediante arbitraje si: (a)
            la disputa cumple con los requisitos para ser presentada en un
            tribunal de reclamaciones menores; o (b) USTED RENUNCIA A LOS
            PROCEDIMIENTOS DE ARBITRAJE EN UN PLAZO DE 30 DÍAS DESDE LA FECHA EN
            LA QUE ACEPTÓ ESTE ACUERDO (el “
            <strong>Plazo de Autoexclusión</strong>”). Puede renunciar a esta
            disposición de arbitraje enviándonos un correo electrónico a la
            dirección{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> con la
            siguiente información: 1) su nombre, (2) su dirección, (3) una
            declaración clara de que no desea solucionar cualquier disputa que
            surja con nosotros mediante arbitraje. En cualquier caso, no nos
            tomaremos su decisión personalmente. De hecho, le prometemos que la
            decisión de autoexclusión de esta disposición de arbitraje no tendrá
            ningún efecto adverso sobre su relación con nosotros. Pero sí que
            debemos aplicar el Plazo de Autoexclusión, por lo que tenga presente
            que{' '}
            <strong>
              toda solicitud de autoexclusión que se reciba después del Plazo de
              Autoexclusión no tendrá validez y deberá cursar sus disputas
              mediante arbitraje o en un tribunal de reclamaciones menores.
            </strong>
          </Text3>
          <Text3 as="p">Procedimientos de Arbitraje</Text3>
          <Text3 as="p">
            Si esta Disposición se aplica y la disputa no se resuelve como se
            establece anteriormente (Resolución de una Reclamación Antes del
            Arbitraje) tanto usted como nosotros podemos iniciar los
            procedimientos de arbitraje. La Asociación de Arbitraje Americana
            (American Arbitration Association, “<strong>AAA</strong>”),
            www.adr.org, o JAMS, www.jamsadr.com mediarán el arbitraje de todas
            las disputas, lo que se hará ante un único árbitro. El arbitraje se
            iniciará de forma individual y nunca como un arbitraje colectivo o
            como una demanda o arbitraje representativos o consolidados. El
            árbitro tomará las decisiones con respecto a todos los temas,
            incluyendo el alcance de esta disposición de arbitraje.
          </Text3>
          <Text3 as="p">
            En el caso de los arbitrajes ante la AAA, cuando las disputas sean
            sobre menos de 75.000 $, se aplicarán los Supplementary Procedures
            for Consumer-Related Disputes (procedimientos complementarios para
            disputas relacionadas con consumidores) de la AAA; cuando las
            disputas sean sobre 75.000 $ o más, se aplicará el Commercial
            Arbitration Rules (reglamento de arbitraje comercial) de la AAA. En
            ambos casos se aplicará el Optional Rules For Emergency Measures Of
            Protection (reglamento facultativo para medidas de protección de
            emergencia) de la AAA. Los reglamentos de la AAA están disponibles
            en www.adr.org o en el número de teléfono 1-800-778-7879. En el caso
            de arbitrajes ante JAMS, se aplicará el Comprehensive Arbitration
            Rules &amp; Procedures (reglamento general de arbitraje y
            procedimientos) y los Recommended Arbitration Discovery Protocols
            For Domestic, Commercial Cases (protocolos de arbitraje recomendados
            para casos domésticos y comerciales) de JAMS. El reglamento de JAMS
            está disponible en www.jamsadr.com o en el número de teléfono
            1-800-352-5267. Esta Disposición será la que regule en caso de que
            exista conflicto con el reglamento de arbitraje aplicable. En ningún
            caso serán de aplicación al arbitraje los reglamentos o
            procedimientos representativos o de demanda colectiva.
          </Text3>
          <Text3 as="p">
            Dado que este Sitio Web y estas Condiciones atañen al comercio
            interestatal, es la Ley Federal de Arbitraje (Federal Arbitration
            Act, “<strong>FAA</strong>”) la que regula el arbitraje de todas las
            disputas. Sin embargo, el árbitro aplicará la ley sustantiva
            pertinente conforme a la FAA y los términos prescriptivos aplicables
            o la condición suspensiva según considere.
          </Text3>
          <Text3 as="p">
            <em>Laudo Arbitral</em> – El árbitro puede adjudicar a título
            individual cualquier compensación posible según la legislación
            vigente, y no tendrá la capacidad de otorgar compensación a, en
            contra de o en beneficio de cualquier persona que no sea parte del
            procedimiento. El árbitro emitirá su decisión por escrito pero no
            tendrá que presentar una declaración sobre los motivos de la misma a
            menos que alguna parte así lo solicite o que sea exigido por la
            legislación aplicable. La decisión será final firme y vinculante
            para las partes, salvo para los derechos de apelación previstos por
            la FAA u otras leyes aplicables, y podrá presentarse en cualquier
            tribunal que tenga jurisdicción sobre las partes a efectos de
            cumplimiento.
          </Text3>
          <Text3 as="p">
            <em>Lugar del Arbitraje</em> – Usted o nosotros podemos iniciar el
            arbitraje tanto en Florida como en el distrito judicial federal que
            corresponda a su dirección de facturación.
          </Text3>
          <Text3 as="p">
            <em>Pago de las Tarifas y Costes de Arbitraje </em>– Siempre que así
            lo haya solicitado por escrito antes del arbitraje, nosotros nos
            encargaremos del pago de todos los cargos por el inicio del
            arbitraje y los cargos por las audiencias de la AAA o JAMS, así como
            de los honorarios, costes y gastos del árbitro si lo ha solicitado
            por escrito al árbitro correspondiente antes o durante la primera
            audiencia probatoria del arbitraje. Sin embargo, usted será
            responsable de todos los cargos y costes adicionales que usted
            incurra durante el arbitraje, que incluyen entre otros, los
            honorarios de los abogados o los peritos. Además de los cargos y
            costes recuperables de acuerdo con la legislación aplicable, si nos
            notifica y negocia de buena fe con nosotros según se establece en la
            sección anterior denominada “Resolución de una Reclamación Antes del
            Arbitraje”, y el árbitro determina que usted es la parte imperante
            del arbitraje, Usted tendrá derecho a recuperar los honorarios y
            gastos razonables de los abogados según determine el árbitro.
          </Text3>
          <Text3 as="p">Renuncia a la Demanda Colectiva</Text3>
          <Text3 as="p">
            Salvo que se establezca lo contrario en esta disposición de
            arbitraje, el árbitro no podrá consolidar las reclamaciones de más
            de una persona, y no podrá presidir ninguna otra forma de demanda o
            procedimiento colectivo o representativo (como una demanda
            colectiva, una demanda consolidada, una demanda representativa, o
            una demanda privada) a menos que usted y nosotros así lo acordemos
            específicamente por escrito tras el inicio del arbitraje. Si decide
            presentar la disputa en un tribunal tras autoexcluirse de esta
            disposición de arbitraje, tal y como se específica anteriormente,
            esta Renuncia a la Demanda Colectiva no le será de aplicación. Ni
            usted ni otros usuarios de este Sitio Web pueden ser representantes
            de un colectivo, miembros de un colectivo o participar de cualquier
            otra forma en un procedimiento colectivo, consolidado o
            representativo sin haber cumplido con los requisitos de
            autoexclusión especificados anteriormente.
          </Text3>
          <Text3 as="p">Renuncia a un Jurado</Text3>
          <Text3 as="p">
            Usted entiende y está de acuerdo con que al aceptar la disposición
            de arbitraje de estas Condiciones, usted y nosotros renunciamos al
            derecho a un juicio con jurado o a un juicio ante un juez en un
            tribunal público. Si no existiera esta disposición de arbitraje,
            usted y nosotros podríamos haber tenido el derecho u oportunidad de
            presentar disputas en un tribunal, ante un juez o un jurado, o a
            participar o ser representado en un caso presentado por otros en un
            tribunal (incluyendo demandas colectivas). Salvo que se indique de
            otro modo más abajo, estos derechos quedan anulados. Otros derechos
            que habría tenido en caso de acudir a un tribunal (por ejemplo, los
            derechos de apelación y ciertos tipos de descubrimientos) pueden
            quedar limitados o también anulados.
          </Text3>
          <Text3 as="p">Divisibilidad</Text3>
          <Text3 as="p">
            En el caso de que una de las cláusulas de esta disposición de
            arbitraje (excepto la cláusula de Renuncia a la Demanda Colectiva)
            se considere ilegal o inaplicable, esa cláusula será eliminada de
            esta disposición de arbitraje manteniendo el resto su plena vigencia
            y efecto. Si la cláusula de Renuncia a la Demanda Colectiva se
            considera ilegal o inaplicable, la disposición de arbitraje al
            completo será inaplicable y la disputa se resolverá en un tribunal.
          </Text3>
          <Text3 as="p">Continuidad</Text3>
          <Text3 as="p">
            Esta disposición de arbitraje permanecerá vigente después de que
            usted complete su Bootcamp Ironhack con nosotros o nuestras filiales
            y deje de utilizar este Sitio Web. No obstante cualquier disposición
            de estas Condiciones en contrario, aceptamos que, si cambiamos de
            forma alguna esta disposición de arbitraje (excepto los cambios en
            la dirección de notificaciones), puede rechazar tal cambio y
            exigirnos la adhesión a la redacción de esta disposición en caso que
            surja una disputa.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDIOMA</Text2>
          <Text3 as="p">
            Las Partes aquí afectadas han exigido expresamente que este acuerdo
            y todos los documentos y notificaciones relacionadas con el mismo,
            sean redactadas en el idioma Inglés.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GENERAL</Text2>
          <Text3 as="p">
            Nosotros pensamos que la comunicación directa soluciona la mayor
            parte de los problemas (si nos da la impresión de que usted no
            cumple con estas Condiciones, se lo haremos saber). Incluso, y dado
            que valoramos esta relación, le proporcionaremos una recomendación
            de acciones correctivas necesarias.
          </Text3>
          <Text3 as="p">
            Sin embargo, ciertas violaciones de estas Condiciones, según nuestro
            criterio, pueden exigir la suspensión inmediata de su acceso a este
            Sitio Web sin que le enviemos una notificación previa. La Ley
            Federal de Arbitraje, la legislación del estado de Florida, y la
            legislación federal de los EE. UU. aplicable, independientemente de
            la elección de las disposiciones legales o del conflicto de leyes,
            regularán estas Condiciones. No se aplicarán leyes extranjeras.
            Salvo las disputas sujetas a arbitraje descritas anteriormente, toda
            disputa relacionada con estas Condiciones o este Sitio Web se
            presentarán en los tribunales del condado de Miami-Dade, Florida. En
            caso de que un tribunal de jurisdicción competente considere que
            cualquiera de las disposiciones de estas Condiciones son nulas,
            incompatibles o inaplicables, tales disposiciones serán limitadas o
            eliminadas en la medida estrictamente necesaria y reemplazadas con
            una disposición válida que represente de la mejor forma posible las
            intenciones de estas Condiciones, de manera que estas se mantengan
            vigentes y aplicables, y no se modifiquen otras condiciones. La no
            aplicación de estas Condiciones no supone una renuncia a nuestros
            derechos. Estas Condiciones constituyen el acuerdo completo entre
            usted y nosotros y, por tanto, reemplazan toda negociación,
            discusión o acuerdo anterior o actual entre cualquier persona sobre
            este Sitio Web. Los derechos de propiedad intelectual, renuncia de
            garantías, declaraciones suyas, indemnizaciones, limitaciones de
            responsabilidad y disposiciones generales se mantendrán vigentes
            tras la terminación de estas Condiciones.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">NOTIFICACIÓN AL CONSUMIDOR DE CALIFORNIA</Text2>
          <Text3 as="p">
            De acuerdo con la sección 1789.3 del Código Civil de California, los
            usuarios tienen derecho a la siguiente notificación de derechos del
            consumidor: El proveedor de este Sitio Web y Servicio es Ironhack,
            990 Biscayne Blvd, Ste. 502, Miami FL 33132. Si tiene alguna
            pregunta o reclamación que hacer sobre este Sitio Web o Servicio,
            puede ponerse en contacto con nosotros directamente a través de la
            dirección de correo electrónico{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>. También
            puede ponerse en contacto enviando un escrito a la dirección
            Ironhack, 990 Biscayne Blvd, Ste. 502, Miami FL 33132. Los
            residentes de California pueden ponerse en contacto con la Unidad de
            Asistencia al Consumidor de la División de Servicios al Consumidor
            del Departamento de Asuntos del Consumidor de California a través
            del correo postal en la dirección 1625 North Market Blvd.,
            Sacramento, CA 95834 o a través del número de teléfono (916)
            445-1254 o (800) 952-5210, personas con dificultades auditivas en
            TDD (800) 326-2297 o TDD (916) 322-1700.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMENTARIOS</Text2>
          <Text3 as="p">
            Todo aquello que usted nos envíe (por ejemplo, comentarios,
            testimonios, citas, preguntas, sugerencias, materiales, de forma
            colectiva “<strong>Comentarios</strong>”) a través de cualquier
            comunicación de la naturaleza que sea (por ejemplo, llamadas,
            comentarios en el blog, correos electrónicos) tendrá un carácter no
            confidencial y de propiedad no exclusiva. Salvo disposición legal
            contraria, por el presente cede todos los derechos, títulos e
            intereses sobre, y nosotros podemos utilizar libremente sin
            atribución o contraprestación ninguna para Usted, cualquier idea,
            conocimiento, concepto, técnica u otra propiedad intelectual o
            derechos de autor incluidos en los Comentarios, tanto si son o no
            patentables, para el fin que sea, incluyendo entre otros el
            desarrollo, la fabricación presente, pasada o futura, las licencias,
            la comercialización y la venta, directa o indirecta de productos y
            servicios utilizando tales Comentarios. En los casos en los que la
            cesión anterior esté prohibida por ley, por el presente nos concede
            una licencia exclusiva, transferible, internacional, libre de cargos
            y pagada íntegramente (incluyendo el derecho a sublicenciar) para
            utilizar y explotar todos los Comentarios según determinemos bajo
            nuestra exclusiva discreción. Sin perjuicio de lo anterior, entiende
            y acepta que nosotros no tenemos obligación alguna de utilizar,
            publicar, reproducir o distribuir tales ideas, conocimientos,
            conceptos o técnicas incluidas en los Comentarios, y no tiene
            derecho alguno de exigir tal utilización, publicación, reproducción
            o distribución.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CONTACTO</Text2>
          <Text3 as="p">
            Si tiene alguna pregunta sobre estas Condiciones o necesita por otro
            motivo ponerse en contacto con nosotros, puede hacer en la dirección{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>.
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "es" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('es')(TermOfUse);
