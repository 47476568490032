import styled from 'styled-components';
import config from '../config';

export const Section = styled('section')`
  background-color: ${config.palette.white};
  border-radius: 6px;
  box-shadow: 0 32px 64px rgba(30, 36, 52, 0.1);
  margin: 0 9.6rem;
  padding: 6.4rem 21.6rem 4.8rem 4.8rem;

  .subtitle {
    color: ${config.palette.darkBlueTransparent('0.6')};
    margin-top: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;

    .subtitle {
      margin-top: 0;
    }
  }
`;
